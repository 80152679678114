<template>
  专业机构
  <select v-model="selected">
    <option disabled value="">专业机构</option>
    <option v-for="item in options" :key="item">{{item.zyjg}}</option>
  </select>
  线条透明度
  <select v-model="opacity">
    <option disabled value="">线条不透明度</option>
    <option v-for="item in opacityOptions" :key="item" :value="item">{{(item * 100).toFixed(0)}}%</option>
  </select>

  <sankey-chart-select :option="option"/>
</template>

<script setup>
// import SankeyChart from "@/views/SankeyChart";
import data from "@/data_zyjg_3clo";
import {computed, ref} from "vue";
import SankeyChartSelect from "@/views/SankeyChartSelect";
// const selected = ref("可再生能源与氢能技术")
const selected = ref("工业和信息化部产业发展促进中心")
const options = computed(() => {
  return data
})
const opacityOptions = ref([
  0.05, 0.1, 0.15, 0.2, 0.25, 0.3, 0.35, 0.4, 0.45, 0.5, 0.55, 0.6, 0.65, 0.7, 0.75, 0.8, 0.85, 0.9, 0.95, 1
])
const opacity = ref(0.6)
const option = computed(() => {
  let opt = data.filter(item => item.zyjg === selected.value)[0]
  opt.series.lineStyle.opacity = Number(opacity.value)
  return JSON.stringify(opt)
  // return data.filter(item => item.series.links[0].target === selected.value)[0]
})
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 20px;
}
</style>
