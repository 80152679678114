<template>
  <!--<div class="titles" style=""><span style="float:left;width: 33%;text-align: left;">项目管理专业机构</span><span>国家重点研发计划重点专项</span><span>国家科学数据中心</span></div>-->
  <div id="chart" style="width:1800px;height:1200px;" ></div>
</template>

<script setup>
import * as echarts from "echarts";
import {nextTick, onMounted, watch} from "vue";
// eslint-disable-next-line no-undef
const props = defineProps(["option"])
onMounted(() => {
  nextTick(() => {
    let option = JSON.parse(props.option)
    let chart = echarts.init(document.getElementById("chart"));
    chart.setOption(option)
  })

})
watch(() => props.option, () => {
  let option = JSON.parse(props.option)
  let chart = echarts.init(document.getElementById("chart"));
  chart.setOption(option)
}, {deep: true})
</script>

<style scoped>
.title {
  margin-top: 10px;
  text-align: left;
  font-size: 20px;
  font-weight: bold;
}
</style>
