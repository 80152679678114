const data =
    [
        {
            "title": {
                show: true,
                text: "{a|项目管理专业机构}{a|国家重点研发计划重点专项}{a|国家科学数据中心} ",
                textStyle: {
                    rich: {
                        a: {
                            // display:'block',
                            // 'margin-right':'100px',
                            width: 645,
                            'text-align': 'left',
                            'float': 'left',
                            fontWeight: 'bold',
                            fontSize: 24,
                        },
                    },
                },
                left:25,
                top: 45,
            },
            toolbox: {
                show: true,
                feature: {
                    saveAsImage: {
                        show: true,
                        name: "专业机构-项目-数据中心桑基图"
                    }
                }
            },

            "series": {
                "data": [
                    {
                        "depth": 0,
                        "itemStyle": {
                            "color": "#471e84"
                        },
                        "name": "工业和信息化部产业发展促进中心",
                        "value": 111
                    },
                    {
                        "depth": 1,
                        "itemStyle": {
                            "color": "#848484"
                        },
                        "name": "智能电网技术与装备",
                        "val": 53,
                        "value": "53(47.75%)"
                    },
                    {
                        "depth": 1,
                        "itemStyle": {
                            "color": "#7996cb"
                        },
                        "name": "材料基因工程关键技术与支撑平台",
                        "val": 32,
                        "value": "32(28.83%)"
                    },
                    {
                        "depth": 1,
                        "itemStyle": {
                            "color": "#ad841f"
                        },
                        "name": "网络空间安全",
                        "val": 23,
                        "value": "23(20.72%)"
                    },
                    {
                        "depth": 1,
                        "itemStyle": {
                            "color": "#95c179"
                        },
                        "name": "制造基础技术与关键部件",
                        "val": 2,
                        "value": "2(1.80%)"
                    },
                    {
                        "depth": 1,
                        "itemStyle": {
                            "color": "#588638"
                        },
                        "name": "物联网与智慧城市关键技术及示范",
                        "val": 1,
                        "value": "1(0.90%)"
                    },
                    {
                        "depth": 2,
                        "itemStyle": {
                            "color": "#757571"
                        },
                        "name": "国家基础学科公共科学数据中心",
                        "val": 78,
                        "value": "78(70.27%)"
                    },
                    {
                        "depth": 2,
                        "itemStyle": {
                            "color": "#86aba0"
                        },
                        "name": "国家材料腐蚀与防护科学数据中心",
                        "val": 32,
                        "value": "32(28.83%)"
                    },
                    {
                        "depth": 2,
                        "itemStyle": {
                            "color": "#afb5ac"
                        },
                        "name": "国家计量科学数据中心",
                        "val": 1,
                        "value": "1(0.90%)"
                    }
                ],
                "emphasis": {
                    "focus": "adjacency"
                },
                "itemStyle": {
                    "borderWidth": 20
                },
                "label": {
                    "fontWeight": "bold",
                    "textBorderWidth": 0.5,
                    "fontSize": 20,
                    "lineHeight": 80,
                    "overflow": "break",
                    "width": 600
                },
                "layout": "none",
                "layoutIterations": 0,
                "levels": [
                    {
                        "depth": 0,
                        "label": {
                            "formatter": "{b}: {@value}"
                        }
                    },
                    {
                        "depth": 1,
                        "label": {
                            "formatter": "{b}: {@value}"
                        }
                    },
                    {
                        "depth": 2,
                        "label": {
                            "formatter": "{b}: {@value}"
                        }
                    }
                ],
                "lineStyle": {
                    "color": "gradient",
                    "curveness": 0,
                    "opacity": 0.3
                },
                "links": [
                    {
                        "source": "工业和信息化部产业发展促进中心",
                        "target": "物联网与智慧城市关键技术及示范",
                        "value": 1
                    },
                    {
                        "source": "物联网与智慧城市关键技术及示范",
                        "target": "国家基础学科公共科学数据中心",
                        "value": 1
                    },
                    {
                        "source": "制造基础技术与关键部件",
                        "target": "国家计量科学数据中心",
                        "value": 1
                    },
                    {
                        "source": "工业和信息化部产业发展促进中心",
                        "target": "制造基础技术与关键部件",
                        "value": 2
                    },
                    {
                        "source": "制造基础技术与关键部件",
                        "target": "国家基础学科公共科学数据中心",
                        "value": 1
                    },
                    {
                        "source": "工业和信息化部产业发展促进中心",
                        "target": "网络空间安全",
                        "value": 23
                    },
                    {
                        "source": "网络空间安全",
                        "target": "国家基础学科公共科学数据中心",
                        "value": 23
                    },
                    {
                        "source": "工业和信息化部产业发展促进中心",
                        "target": "材料基因工程关键技术与支撑平台",
                        "value": 32
                    },
                    {
                        "source": "材料基因工程关键技术与支撑平台",
                        "target": "国家材料腐蚀与防护科学数据中心",
                        "value": 32
                    },
                    {
                        "source": "工业和信息化部产业发展促进中心",
                        "target": "智能电网技术与装备",
                        "value": 53
                    },
                    {
                        "source": "智能电网技术与装备",
                        "target": "国家基础学科公共科学数据中心",
                        "value": 53
                    }
                ],
                "nodeGap": 30,
                "nodeWidth": 30,
                "right": "25%",
                top: "7%",
                bottom: "4%",
                left:"25",
                "type": "sankey"
            },
            "zyjg": "工业和信息化部产业发展促进中心"
        },
        {
            "title": {
                show: true,
                text: "{a|项目管理专业机构}{a|国家重点研发计划重点专项}{a|国家科学数据中心} ",
                textStyle: {
                    rich: {
                        a: {
                            width: 645,
                            'text-align': 'left',
                            'float': 'left',
                            fontWeight: 'bold',
                            fontSize: 24,
                        },
                    },
                },
                left:25,
                top: 45,
            },
            toolbox: {
                show: true,
                feature: {
                    saveAsImage: {
                        show: true,
                        name: "专业机构-项目-数据中心桑基图"
                    }
                }
            },
            "series": {
                "data": [
                    {
                        "depth": 0,
                        "itemStyle": {
                            "color": "#913342"
                        },
                        "name": "国家卫生健康委医药卫生科技发展研究中心",
                        "value": 157
                    },
                    {
                        "depth": 1,
                        "itemStyle": {
                            "color": "#984823"
                        },
                        "name": "精准医学研究",
                        "val": 105,
                        "value": "105(66.88%)"
                    },
                    {
                        "depth": 1,
                        "itemStyle": {
                            "color": "#c59610"
                        },
                        "name": "生殖健康及重大出生缺陷防控研究",
                        "val": 52,
                        "value": "52(33.12%)"
                    },
                    {
                        "depth": 2,
                        "itemStyle": {
                            "color": "#a7ae8e"
                        },
                        "name": "国家人口健康科学数据中心",
                        "val": 121,
                        "value": "121(77.07%)"
                    },
                    {
                        "depth": 2,
                        "itemStyle": {
                            "color": "#637270"
                        },
                        "name": "国家基因组科学数据中心",
                        "val": 36,
                        "value": "36(22.93%)"
                    }
                ],
                "emphasis": {
                    "focus": "adjacency"
                },
                "itemStyle": {
                    "borderWidth": 20
                },
                "label": {
                    "fontWeight": "bold",
                    "textBorderWidth": 0.5,
                    "fontSize": 20,
                    "lineHeight": 80,
                    "overflow": "break",
                    "width": 600
                },
                "layout": "none",
                "layoutIterations": 0,
                "levels": [
                    {
                        "depth": 0,
                        "label": {
                            "formatter": "{b}: {@value}"
                        }
                    },
                    {
                        "depth": 1,
                        "label": {
                            "formatter": "{b}: {@value}"
                        }
                    },
                    {
                        "depth": 2,
                        "label": {
                            "formatter": "{b}: {@value}"
                        }
                    }
                ],
                "lineStyle": {
                    "color": "gradient",
                    "curveness": 0,
                    "opacity": 0.3
                },
                "links": [
                    {
                        "source": "生殖健康及重大出生缺陷防控研究",
                        "target": "国家基因组科学数据中心",
                        "value": 7
                    },
                    {
                        "source": "精准医学研究",
                        "target": "国家基因组科学数据中心",
                        "value": 29
                    },
                    {
                        "source": "国家卫生健康委医药卫生科技发展研究中心",
                        "target": "生殖健康及重大出生缺陷防控研究",
                        "value": 52
                    },
                    {
                        "source": "生殖健康及重大出生缺陷防控研究",
                        "target": "国家人口健康科学数据中心",
                        "value": 45
                    },
                    {
                        "source": "国家卫生健康委医药卫生科技发展研究中心",
                        "target": "精准医学研究",
                        "value": 105
                    },
                    {
                        "source": "精准医学研究",
                        "target": "国家人口健康科学数据中心",
                        "value": 76
                    }
                ],
                "nodeGap": 30,
                "nodeWidth": 30,
                "right": "25%",
                top: "7%",
                bottom: "4%",
                left:"25",
                "type": "sankey"
            },
            "zyjg": "国家卫生健康委医药卫生科技发展研究中心"
        },
        {
            "title": {
                show: true,
                text: "{a|项目管理专业机构}{a|国家重点研发计划重点专项}{a|国家科学数据中心} ",
                textStyle: {
                    rich: {
                        a: {
                            // display:'block',
                            // 'margin-right':'100px',
                            width: 645,
                            'text-align': 'left',
                            'float': 'left',
                            fontWeight: 'bold',
                            fontSize: 24,
                        },
                    },
                },
                left:25,
                top: 45,
            },
            toolbox: {
                show: true,
                feature: {
                    saveAsImage: {
                        show: true,
                        name: "专业机构-项目-数据中心桑基图"
                    }
                }
            },
            "series": {
                "data": [
                    {
                        "depth": 0,
                        "itemStyle": {
                            "color": "#9e1862"
                        },
                        "name": "中国科学技术交流中心",
                        "value": 126
                    },
                    {
                        "depth": 1,
                        "itemStyle": {
                            "color": "#6ea647"
                        },
                        "name": "政府间国际科技创新合作重点专项",
                        "val": 125,
                        "value": "125(99.21%)"
                    },
                    {
                        "depth": 1,
                        "itemStyle": {
                            "color": "#edad81"
                        },
                        "name": "战略性科技创新合作",
                        "val": 1,
                        "value": "1(0.79%)"
                    },
                    {
                        "depth": 2,
                        "itemStyle": {
                            "color": "#757571"
                        },
                        "name": "国家基础学科公共科学数据中心",
                        "val": 49,
                        "value": "49(38.89%)"
                    },
                    {
                        "depth": 2,
                        "itemStyle": {
                            "color": "#b3af76"
                        },
                        "name": "国家农业科学数据中心",
                        "val": 25,
                        "value": "25(19.84%)"
                    },
                    {
                        "depth": 2,
                        "itemStyle": {
                            "color": "#a7ae8e"
                        },
                        "name": "国家人口健康科学数据中心",
                        "val": 14,
                        "value": "14(11.11%)"
                    },
                    {
                        "depth": 2,
                        "itemStyle": {
                            "color": "#afb5ac"
                        },
                        "name": "国家计量科学数据中心",
                        "val": 9,
                        "value": "9(7.14%)"
                    },
                    {
                        "depth": 2,
                        "itemStyle": {
                            "color": "#cfe0d9"
                        },
                        "name": "国家天文科学数据中心",
                        "val": 4,
                        "value": "4(3.17%)"
                    },
                    {
                        "depth": 2,
                        "itemStyle": {
                            "color": "#8dc3aa"
                        },
                        "name": "国家对地观测科学数据中心",
                        "val": 4,
                        "value": "4(3.17%)"
                    },
                    {
                        "depth": 2,
                        "itemStyle": {
                            "color": "#86aba0"
                        },
                        "name": "国家微生物科学数据中心",
                        "val": 4,
                        "value": "4(3.17%)"
                    },
                    {
                        "depth": 2,
                        "itemStyle": {
                            "color": "#6b7257"
                        },
                        "name": "国家极地科学数据中心",
                        "val": 3,
                        "value": "3(2.38%)"
                    },
                    {
                        "depth": 2,
                        "itemStyle": {
                            "color": "#acc3b6"
                        },
                        "name": "国家生态科学数据中心",
                        "val": 3,
                        "value": "3(2.38%)"
                    },
                    {
                        "depth": 2,
                        "itemStyle": {
                            "color": "#86aba0"
                        },
                        "name": "国家材料腐蚀与防护科学数据中心",
                        "val": 2,
                        "value": "2(1.59%)"
                    },
                    {
                        "depth": 2,
                        "itemStyle": {
                            "color": "#006b5c"
                        },
                        "name": "国家高能物理科学数据中心",
                        "val": 2,
                        "value": "2(1.59%)"
                    },
                    {
                        "depth": 2,
                        "itemStyle": {
                            "color": "#e5e3cf"
                        },
                        "name": "国家气象科学数据中心",
                        "val": 2,
                        "value": "2(1.59%)"
                    },
                    {
                        "depth": 2,
                        "itemStyle": {
                            "color": "#637270"
                        },
                        "name": "国家基因组科学数据中心",
                        "val": 2,
                        "value": "2(1.59%)"
                    },
                    {
                        "depth": 2,
                        "itemStyle": {
                            "color": "#0e8956"
                        },
                        "name": "国家空间科学数据中心",
                        "val": 1,
                        "value": "1(0.79%)"
                    },
                    {
                        "depth": 2,
                        "itemStyle": {
                            "color": "#495946"
                        },
                        "name": "国家冰川冻土沙漠科学数据中心",
                        "val": 1,
                        "value": "1(0.79%)"
                    },
                    {
                        "depth": 2,
                        "itemStyle": {
                            "color": "#e0d341"
                        },
                        "name": "国家林业和草原科学数据中心",
                        "val": 1,
                        "value": "1(0.79%)"
                    }
                ],
                "emphasis": {
                    "focus": "adjacency"
                },
                "itemStyle": {
                    "borderWidth": 20
                },
                "label": {
                    "fontWeight": "bold",
                    "textBorderWidth": 0.5,
                    "fontSize": 20,
                    "lineHeight": 80,
                    "overflow": "break",
                    "width": 600
                },
                "layout": "none",
                "layoutIterations": 0,
                "levels": [
                    {
                        "depth": 0,
                        "label": {
                            "formatter": "{b}: {@value}"
                        }
                    },
                    {
                        "depth": 1,
                        "label": {
                            "formatter": "{b}: {@value}"
                        }
                    },
                    {
                        "depth": 2,
                        "label": {
                            "formatter": "{b}: {@value}"
                        }
                    }
                ],
                "lineStyle": {
                    "color": "gradient",
                    "curveness": 0,
                    "opacity": 0.3
                },
                "links": [
                    {
                        "source": "政府间国际科技创新合作重点专项",
                        "target": "国家空间科学数据中心",
                        "value": 1
                    },
                    {
                        "source": "政府间国际科技创新合作重点专项",
                        "target": "国家冰川冻土沙漠科学数据中心",
                        "value": 1
                    },
                    {
                        "source": "中国科学技术交流中心",
                        "target": "战略性科技创新合作",
                        "value": 1
                    },
                    {
                        "source": "战略性科技创新合作",
                        "target": "国家天文科学数据中心",
                        "value": 1
                    },
                    {
                        "source": "政府间国际科技创新合作重点专项",
                        "target": "国家林业和草原科学数据中心",
                        "value": 1
                    },
                    {
                        "source": "政府间国际科技创新合作重点专项",
                        "target": "国家材料腐蚀与防护科学数据中心",
                        "value": 2
                    },
                    {
                        "source": "政府间国际科技创新合作重点专项",
                        "target": "国家高能物理科学数据中心",
                        "value": 2
                    },
                    {
                        "source": "政府间国际科技创新合作重点专项",
                        "target": "国家气象科学数据中心",
                        "value": 2
                    },
                    {
                        "source": "政府间国际科技创新合作重点专项",
                        "target": "国家基因组科学数据中心",
                        "value": 2
                    },
                    {
                        "source": "政府间国际科技创新合作重点专项",
                        "target": "国家极地科学数据中心",
                        "value": 3
                    },
                    {
                        "source": "政府间国际科技创新合作重点专项",
                        "target": "国家天文科学数据中心",
                        "value": 3
                    },
                    {
                        "source": "政府间国际科技创新合作重点专项",
                        "target": "国家生态科学数据中心",
                        "value": 3
                    },
                    {
                        "source": "政府间国际科技创新合作重点专项",
                        "target": "国家对地观测科学数据中心",
                        "value": 4
                    },
                    {
                        "source": "政府间国际科技创新合作重点专项",
                        "target": "国家微生物科学数据中心",
                        "value": 4
                    },
                    {
                        "source": "政府间国际科技创新合作重点专项",
                        "target": "国家计量科学数据中心",
                        "value": 9
                    },
                    {
                        "source": "政府间国际科技创新合作重点专项",
                        "target": "国家人口健康科学数据中心",
                        "value": 14
                    },
                    {
                        "source": "政府间国际科技创新合作重点专项",
                        "target": "国家农业科学数据中心",
                        "value": 25
                    },
                    {
                        "source": "中国科学技术交流中心",
                        "target": "政府间国际科技创新合作重点专项",
                        "value": 125
                    },
                    {
                        "source": "政府间国际科技创新合作重点专项",
                        "target": "国家基础学科公共科学数据中心",
                        "value": 49
                    }
                ],
                "nodeGap": 30,
                "nodeWidth": 30,
                "right": "25%",
                top: "7%",
                bottom: "4%",
                left:"25",
                "type": "sankey"
            },
            "zyjg": "中国科学技术交流中心"
        },
        {
            "title": {
                show: true,
                text: "{a|项目管理专业机构}{a|国家重点研发计划重点专项}{a|国家科学数据中心} ",
                textStyle: {
                    rich: {
                        a: {
                            // display:'block',
                            // 'margin-right':'100px',
                            width: 645,
                            'text-align': 'left',
                            'float': 'left',
                            fontWeight: 'bold',
                            fontSize: 24,
                        },
                    },
                },
                left:25,
                top: 45,
            },
            toolbox: {
                show: true,
                feature: {
                    saveAsImage: {
                        show: true,
                        name: "专业机构-项目-数据中心桑基图"
                    }
                }
            },
            "series": {
                "data": [
                    {
                        "depth": 0,
                        "itemStyle": {
                            "color": "#ce1c3a"
                        },
                        "name": "科学技术部高技术研究发展中心",
                        "value": 758
                    },
                    {
                        "depth": 1,
                        "itemStyle": {
                            "color": "#626363"
                        },
                        "name": "重点基础材料技术提升与产业化",
                        "val": 104,
                        "value": "104(13.72%)"
                    },
                    {
                        "depth": 1,
                        "itemStyle": {
                            "color": "#6587c4"
                        },
                        "name": "增材制造与激光制造",
                        "val": 67,
                        "value": "67(8.84%)"
                    },
                    {
                        "depth": 1,
                        "itemStyle": {
                            "color": "#79a8d3"
                        },
                        "name": "重大科学仪器设备开发",
                        "val": 63,
                        "value": "63(8.31%)"
                    },
                    {
                        "depth": 1,
                        "itemStyle": {
                            "color": "#f5c63a"
                        },
                        "name": "战略性先进电子材料",
                        "val": 63,
                        "value": "63(8.31%)"
                    },
                    {
                        "depth": 1,
                        "itemStyle": {
                            "color": "#c95e18"
                        },
                        "name": "新能源汽车",
                        "val": 57,
                        "value": "57(7.52%)"
                    },
                    {
                        "depth": 1,
                        "itemStyle": {
                            "color": "#3376b5"
                        },
                        "name": "煤炭清洁高效利用和新型节能技术",
                        "val": 51,
                        "value": "51(6.73%)"
                    },
                    {
                        "depth": 1,
                        "itemStyle": {
                            "color": "#9abddf"
                        },
                        "name": "纳米科技",
                        "val": 43,
                        "value": "43(5.67%)"
                    },
                    {
                        "depth": 1,
                        "itemStyle": {
                            "color": "#a4ca8b"
                        },
                        "name": "智能机器人",
                        "val": 42,
                        "value": "42(5.54%)"
                    },
                    {
                        "depth": 1,
                        "itemStyle": {
                            "color": "#525252"
                        },
                        "name": "云计算和大数据",
                        "val": 37,
                        "value": "37(4.88%)"
                    },
                    {
                        "depth": 1,
                        "itemStyle": {
                            "color": "#7b5f27"
                        },
                        "name": "高性能计算",
                        "val": 35,
                        "value": "35(4.62%)"
                    },
                    {
                        "depth": 1,
                        "itemStyle": {
                            "color": "#37552b"
                        },
                        "name": "蛋白质机器与生命过程调控",
                        "val": 33,
                        "value": "33(4.35%)"
                    },
                    {
                        "depth": 1,
                        "itemStyle": {
                            "color": "#c1c0c0"
                        },
                        "name": "地球观测与导航",
                        "val": 30,
                        "value": "30(3.96%)"
                    },
                    {
                        "depth": 1,
                        "itemStyle": {
                            "color": "#eaa06d"
                        },
                        "name": "大科学装置前沿研究",
                        "val": 29,
                        "value": "29(3.83%)"
                    },
                    {
                        "depth": 1,
                        "itemStyle": {
                            "color": "#2a4c85"
                        },
                        "name": "全球变化及应对",
                        "val": 28,
                        "value": "28(3.69%)"
                    },
                    {
                        "depth": 1,
                        "itemStyle": {
                            "color": "#b05322"
                        },
                        "name": "量子调控与量子信息",
                        "val": 26,
                        "value": "26(3.43%)"
                    },
                    {
                        "depth": 1,
                        "itemStyle": {
                            "color": "#4c7635"
                        },
                        "name": "现代服务业共性关键技术研发及应用示范",
                        "val": 20,
                        "value": "20(2.64%)"
                    },
                    {
                        "depth": 1,
                        "itemStyle": {
                            "color": "#f7d980"
                        },
                        "name": "先进轨道交通",
                        "val": 11,
                        "value": "11(1.45%)"
                    },
                    {
                        "depth": 1,
                        "itemStyle": {
                            "color": "#b3d29e"
                        },
                        "name": "可再生能源与氢能技术",
                        "val": 7,
                        "value": "7(0.92%)"
                    },
                    {
                        "depth": 1,
                        "itemStyle": {
                            "color": "#f6cd50"
                        },
                        "name": "深地资源勘查开采",
                        "val": 3,
                        "value": "3(0.40%)"
                    },
                    {
                        "depth": 1,
                        "itemStyle": {
                            "color": "#89b3d9"
                        },
                        "name": "综合交通运输与智能交通",
                        "val": 3,
                        "value": "3(0.40%)"
                    },
                    {
                        "depth": 1,
                        "itemStyle": {
                            "color": "#6ea647"
                        },
                        "name": "政府间国际科技创新合作重点专项",
                        "val": 1,
                        "value": "1(0.13%)"
                    },
                    {
                        "depth": 1,
                        "itemStyle": {
                            "color": "#848484"
                        },
                        "name": "智能电网技术与装备",
                        "val": 1,
                        "value": "1(0.13%)"
                    },
                    {
                        "depth": 1,
                        "itemStyle": {
                            "color": "#2a4c85"
                        },
                        "name": "变革性技术关键科学问题",
                        "val": 1,
                        "value": "1(0.13%)"
                    },
                    {
                        "depth": 1,
                        "itemStyle": {
                            "color": "#947226"
                        },
                        "name": "国家质量基础的共性技术研究与应用",
                        "val": 1,
                        "value": "1(0.13%)"
                    },
                    {
                        "depth": 1,
                        "itemStyle": {
                            "color": "#8ca5d3"
                        },
                        "name": "引力波探测",
                        "val": 1,
                        "value": "1(0.13%)"
                    },
                    {
                        "depth": 1,
                        "itemStyle": {
                            "color": "#747474"
                        },
                        "name": "核安全与先进核能技术",
                        "val": 1,
                        "value": "1(0.13%)"
                    },
                    {
                        "depth": 2,
                        "itemStyle": {
                            "color": "#757571"
                        },
                        "name": "国家基础学科公共科学数据中心",
                        "val": 562,
                        "value": "562(74.14%)"
                    },
                    {
                        "depth": 2,
                        "itemStyle": {
                            "color": "#afb5ac"
                        },
                        "name": "国家计量科学数据中心",
                        "val": 49,
                        "value": "49(6.46%)"
                    },
                    {
                        "depth": 2,
                        "itemStyle": {
                            "color": "#8dc3aa"
                        },
                        "name": "国家对地观测科学数据中心",
                        "val": 25,
                        "value": "25(3.30%)"
                    },
                    {
                        "depth": 2,
                        "itemStyle": {
                            "color": "#a7ae8e"
                        },
                        "name": "国家人口健康科学数据中心",
                        "val": 21,
                        "value": "21(2.77%)"
                    },
                    {
                        "depth": 2,
                        "itemStyle": {
                            "color": "#6b7257"
                        },
                        "name": "国家地球系统科学数据中心",
                        "val": 19,
                        "value": "19(2.51%)"
                    },
                    {
                        "depth": 2,
                        "itemStyle": {
                            "color": "#006b5c"
                        },
                        "name": "国家高能物理科学数据中心",
                        "val": 18,
                        "value": "18(2.37%)"
                    },
                    {
                        "depth": 2,
                        "itemStyle": {
                            "color": "#cfe0d9"
                        },
                        "name": "国家天文科学数据中心",
                        "val": 16,
                        "value": "16(2.11%)"
                    },
                    {
                        "depth": 2,
                        "itemStyle": {
                            "color": "#86aba0"
                        },
                        "name": "国家材料腐蚀与防护科学数据中心",
                        "val": 15,
                        "value": "15(1.98%)"
                    },
                    {
                        "depth": 2,
                        "itemStyle": {
                            "color": "#637270"
                        },
                        "name": "国家基因组科学数据中心",
                        "val": 8,
                        "value": "8(1.06%)"
                    },
                    {
                        "depth": 2,
                        "itemStyle": {
                            "color": "#e5e3cf"
                        },
                        "name": "国家气象科学数据中心",
                        "val": 7,
                        "value": "7(0.92%)"
                    },
                    {
                        "depth": 2,
                        "itemStyle": {
                            "color": "#acc3b6"
                        },
                        "name": "国家生态科学数据中心",
                        "val": 6,
                        "value": "6(0.79%)"
                    },
                    {
                        "depth": 2,
                        "itemStyle": {
                            "color": "#0e8956"
                        },
                        "name": "国家空间科学数据中心",
                        "val": 5,
                        "value": "5(0.66%)"
                    },
                    {
                        "depth": 2,
                        "itemStyle": {
                            "color": "#b3af76"
                        },
                        "name": "国家农业科学数据中心",
                        "val": 4,
                        "value": "4(0.53%)"
                    },
                    {
                        "depth": 2,
                        "itemStyle": {
                            "color": "#86aba0"
                        },
                        "name": "国家微生物科学数据中心",
                        "val": 2,
                        "value": "2(0.26%)"
                    },
                    {
                        "depth": 2,
                        "itemStyle": {
                            "color": "#d3c755"
                        },
                        "name": "国家地震科学数据中心",
                        "val": 1,
                        "value": "1(0.13%)"
                    }
                ],
                "emphasis": {
                    "focus": "adjacency"
                },
                "itemStyle": {
                    "borderWidth": 20
                },
                "label": {
                    "fontWeight": "bold",
                    "textBorderWidth": 0.5,
                    "fontSize": 20,
                    "lineHeight": 80,
                    "overflow": "break",
                    "width": 600
                },
                "layout": "none",
                "layoutIterations": 0,
                "levels": [
                    {
                        "depth": 0,
                        "label": {
                            "formatter": "{b}: {@value}"
                        }
                    },
                    {
                        "depth": 1,
                        "label": {
                            "formatter": "{b}: {@value}"
                        }
                    },
                    {
                        "depth": 2,
                        "label": {
                            "formatter": "{b}: {@value}"
                        }
                    }
                ],
                "lineStyle": {
                    "color": "gradient",
                    "curveness": 0,
                    "opacity": 0.3
                },
                "links": [
                    {
                        "source": "云计算和大数据",
                        "target": "国家人口健康科学数据中心",
                        "value": 1
                    },
                    {
                        "source": "智能机器人",
                        "target": "国家天文科学数据中心",
                        "value": 1
                    },
                    {
                        "source": "科学技术部高技术研究发展中心",
                        "target": "政府间国际科技创新合作重点专项",
                        "value": 1
                    },
                    {
                        "source": "政府间国际科技创新合作重点专项",
                        "target": "国家基础学科公共科学数据中心",
                        "value": 1
                    },
                    {
                        "source": "科学技术部高技术研究发展中心",
                        "target": "智能电网技术与装备",
                        "value": 1
                    },
                    {
                        "source": "智能电网技术与装备",
                        "target": "国家基础学科公共科学数据中心",
                        "value": 1
                    },
                    {
                        "source": "深地资源勘查开采",
                        "target": "国家对地观测科学数据中心",
                        "value": 1
                    },
                    {
                        "source": "新能源汽车",
                        "target": "国家材料腐蚀与防护科学数据中心",
                        "value": 1
                    },
                    {
                        "source": "地球观测与导航",
                        "target": "国家天文科学数据中心",
                        "value": 1
                    },
                    {
                        "source": "科学技术部高技术研究发展中心",
                        "target": "变革性技术关键科学问题",
                        "value": 1
                    },
                    {
                        "source": "变革性技术关键科学问题",
                        "target": "国家天文科学数据中心",
                        "value": 1
                    },
                    {
                        "source": "重大科学仪器设备开发",
                        "target": "国家气象科学数据中心",
                        "value": 1
                    },
                    {
                        "source": "高性能计算",
                        "target": "国家天文科学数据中心",
                        "value": 1
                    },
                    {
                        "source": "战略性先进电子材料",
                        "target": "国家材料腐蚀与防护科学数据中心",
                        "value": 1
                    },
                    {
                        "source": "科学技术部高技术研究发展中心",
                        "target": "国家质量基础的共性技术研究与应用",
                        "value": 1
                    },
                    {
                        "source": "国家质量基础的共性技术研究与应用",
                        "target": "国家基础学科公共科学数据中心",
                        "value": 1
                    },
                    {
                        "source": "科学技术部高技术研究发展中心",
                        "target": "引力波探测",
                        "value": 1
                    },
                    {
                        "source": "引力波探测",
                        "target": "国家天文科学数据中心",
                        "value": 1
                    },
                    {
                        "source": "重点基础材料技术提升与产业化",
                        "target": "国家农业科学数据中心",
                        "value": 1
                    },
                    {
                        "source": "全球变化及应对",
                        "target": "国家对地观测科学数据中心",
                        "value": 1
                    },
                    {
                        "source": "全球变化及应对",
                        "target": "国家基础学科公共科学数据中心",
                        "value": 1
                    },
                    {
                        "source": "增材制造与激光制造",
                        "target": "国家高能物理科学数据中心",
                        "value": 1
                    },
                    {
                        "source": "智能机器人",
                        "target": "国家高能物理科学数据中心",
                        "value": 1
                    },
                    {
                        "source": "蛋白质机器与生命过程调控",
                        "target": "国家农业科学数据中心",
                        "value": 1
                    },
                    {
                        "source": "重大科学仪器设备开发",
                        "target": "国家对地观测科学数据中心",
                        "value": 1
                    },
                    {
                        "source": "科学技术部高技术研究发展中心",
                        "target": "核安全与先进核能技术",
                        "value": 1
                    },
                    {
                        "source": "核安全与先进核能技术",
                        "target": "国家基础学科公共科学数据中心",
                        "value": 1
                    },
                    {
                        "source": "重点基础材料技术提升与产业化",
                        "target": "国家微生物科学数据中心",
                        "value": 1
                    },
                    {
                        "source": "地球观测与导航",
                        "target": "国家地震科学数据中心",
                        "value": 1
                    },
                    {
                        "source": "量子调控与量子信息",
                        "target": "国家计量科学数据中心",
                        "value": 1
                    },
                    {
                        "source": "重大科学仪器设备开发",
                        "target": "国家微生物科学数据中心",
                        "value": 1
                    },
                    {
                        "source": "云计算和大数据",
                        "target": "国家计量科学数据中心",
                        "value": 1
                    },
                    {
                        "source": "大科学装置前沿研究",
                        "target": "国家空间科学数据中心",
                        "value": 2
                    },
                    {
                        "source": "重大科学仪器设备开发",
                        "target": "国家生态科学数据中心",
                        "value": 2
                    },
                    {
                        "source": "战略性先进电子材料",
                        "target": "国家农业科学数据中心",
                        "value": 2
                    },
                    {
                        "source": "科学技术部高技术研究发展中心",
                        "target": "深地资源勘查开采",
                        "value": 3
                    },
                    {
                        "source": "深地资源勘查开采",
                        "target": "国家基础学科公共科学数据中心",
                        "value": 2
                    },
                    {
                        "source": "纳米科技",
                        "target": "国家人口健康科学数据中心",
                        "value": 2
                    },
                    {
                        "source": "现代服务业共性关键技术研发及应用示范",
                        "target": "国家计量科学数据中心",
                        "value": 2
                    },
                    {
                        "source": "地球观测与导航",
                        "target": "国家空间科学数据中心",
                        "value": 3
                    },
                    {
                        "source": "科学技术部高技术研究发展中心",
                        "target": "综合交通运输与智能交通",
                        "value": 3
                    },
                    {
                        "source": "综合交通运输与智能交通",
                        "target": "国家基础学科公共科学数据中心",
                        "value": 3
                    },
                    {
                        "source": "战略性先进电子材料",
                        "target": "国家计量科学数据中心",
                        "value": 3
                    },
                    {
                        "source": "地球观测与导航",
                        "target": "国家地球系统科学数据中心",
                        "value": 3
                    },
                    {
                        "source": "智能机器人",
                        "target": "国家人口健康科学数据中心",
                        "value": 4
                    },
                    {
                        "source": "全球变化及应对",
                        "target": "国家生态科学数据中心",
                        "value": 4
                    },
                    {
                        "source": "全球变化及应对",
                        "target": "国家气象科学数据中心",
                        "value": 6
                    },
                    {
                        "source": "大科学装置前沿研究",
                        "target": "国家基础学科公共科学数据中心",
                        "value": 6
                    },
                    {
                        "source": "重大科学仪器设备开发",
                        "target": "国家高能物理科学数据中心",
                        "value": 6
                    },
                    {
                        "source": "蛋白质机器与生命过程调控",
                        "target": "国家人口健康科学数据中心",
                        "value": 7
                    },
                    {
                        "source": "智能机器人",
                        "target": "国家计量科学数据中心",
                        "value": 7
                    },
                    {
                        "source": "增材制造与激光制造",
                        "target": "国家人口健康科学数据中心",
                        "value": 7
                    },
                    {
                        "source": "科学技术部高技术研究发展中心",
                        "target": "可再生能源与氢能技术",
                        "value": 7
                    },
                    {
                        "source": "可再生能源与氢能技术",
                        "target": "国家基础学科公共科学数据中心",
                        "value": 7
                    },
                    {
                        "source": "增材制造与激光制造",
                        "target": "国家计量科学数据中心",
                        "value": 7
                    },
                    {
                        "source": "蛋白质机器与生命过程调控",
                        "target": "国家基因组科学数据中心",
                        "value": 8
                    },
                    {
                        "source": "大科学装置前沿研究",
                        "target": "国家高能物理科学数据中心",
                        "value": 10
                    },
                    {
                        "source": "重点基础材料技术提升与产业化",
                        "target": "国家计量科学数据中心",
                        "value": 10
                    },
                    {
                        "source": "科学技术部高技术研究发展中心",
                        "target": "先进轨道交通",
                        "value": 11
                    },
                    {
                        "source": "先进轨道交通",
                        "target": "国家基础学科公共科学数据中心",
                        "value": 11
                    },
                    {
                        "source": "科学技术部高技术研究发展中心",
                        "target": "大科学装置前沿研究",
                        "value": 29
                    },
                    {
                        "source": "大科学装置前沿研究",
                        "target": "国家天文科学数据中心",
                        "value": 11
                    },
                    {
                        "source": "重点基础材料技术提升与产业化",
                        "target": "国家材料腐蚀与防护科学数据中心",
                        "value": 13
                    },
                    {
                        "source": "科学技术部高技术研究发展中心",
                        "target": "全球变化及应对",
                        "value": 28
                    },
                    {
                        "source": "全球变化及应对",
                        "target": "国家地球系统科学数据中心",
                        "value": 16
                    },
                    {
                        "source": "科学技术部高技术研究发展中心",
                        "target": "蛋白质机器与生命过程调控",
                        "value": 33
                    },
                    {
                        "source": "蛋白质机器与生命过程调控",
                        "target": "国家基础学科公共科学数据中心",
                        "value": 17
                    },
                    {
                        "source": "科学技术部高技术研究发展中心",
                        "target": "现代服务业共性关键技术研发及应用示范",
                        "value": 20
                    },
                    {
                        "source": "现代服务业共性关键技术研发及应用示范",
                        "target": "国家基础学科公共科学数据中心",
                        "value": 18
                    },
                    {
                        "source": "重大科学仪器设备开发",
                        "target": "国家计量科学数据中心",
                        "value": 18
                    },
                    {
                        "source": "科学技术部高技术研究发展中心",
                        "target": "地球观测与导航",
                        "value": 30
                    },
                    {
                        "source": "地球观测与导航",
                        "target": "国家对地观测科学数据中心",
                        "value": 22
                    },
                    {
                        "source": "科学技术部高技术研究发展中心",
                        "target": "量子调控与量子信息",
                        "value": 26
                    },
                    {
                        "source": "量子调控与量子信息",
                        "target": "国家基础学科公共科学数据中心",
                        "value": 25
                    },
                    {
                        "source": "科学技术部高技术研究发展中心",
                        "target": "智能机器人",
                        "value": 42
                    },
                    {
                        "source": "智能机器人",
                        "target": "国家基础学科公共科学数据中心",
                        "value": 29
                    },
                    {
                        "source": "科学技术部高技术研究发展中心",
                        "target": "高性能计算",
                        "value": 35
                    },
                    {
                        "source": "高性能计算",
                        "target": "国家基础学科公共科学数据中心",
                        "value": 34
                    },
                    {
                        "source": "科学技术部高技术研究发展中心",
                        "target": "重大科学仪器设备开发",
                        "value": 63
                    },
                    {
                        "source": "重大科学仪器设备开发",
                        "target": "国家基础学科公共科学数据中心",
                        "value": 34
                    },
                    {
                        "source": "科学技术部高技术研究发展中心",
                        "target": "云计算和大数据",
                        "value": 37
                    },
                    {
                        "source": "云计算和大数据",
                        "target": "国家基础学科公共科学数据中心",
                        "value": 35
                    },
                    {
                        "source": "科学技术部高技术研究发展中心",
                        "target": "纳米科技",
                        "value": 43
                    },
                    {
                        "source": "纳米科技",
                        "target": "国家基础学科公共科学数据中心",
                        "value": 41
                    },
                    {
                        "source": "科学技术部高技术研究发展中心",
                        "target": "煤炭清洁高效利用和新型节能技术",
                        "value": 51
                    },
                    {
                        "source": "煤炭清洁高效利用和新型节能技术",
                        "target": "国家基础学科公共科学数据中心",
                        "value": 51
                    },
                    {
                        "source": "科学技术部高技术研究发展中心",
                        "target": "增材制造与激光制造",
                        "value": 67
                    },
                    {
                        "source": "增材制造与激光制造",
                        "target": "国家基础学科公共科学数据中心",
                        "value": 52
                    },
                    {
                        "source": "科学技术部高技术研究发展中心",
                        "target": "新能源汽车",
                        "value": 57
                    },
                    {
                        "source": "新能源汽车",
                        "target": "国家基础学科公共科学数据中心",
                        "value": 56
                    },
                    {
                        "source": "科学技术部高技术研究发展中心",
                        "target": "战略性先进电子材料",
                        "value": 63
                    },
                    {
                        "source": "战略性先进电子材料",
                        "target": "国家基础学科公共科学数据中心",
                        "value": 57
                    },
                    {
                        "source": "科学技术部高技术研究发展中心",
                        "target": "重点基础材料技术提升与产业化",
                        "value": 104
                    },
                    {
                        "source": "重点基础材料技术提升与产业化",
                        "target": "国家基础学科公共科学数据中心",
                        "value": 79
                    }
                ],
                "nodeGap": 30,
                "nodeWidth": 30,
                "right": "25%",
                top: "7%",
                bottom: "4%",
                left:"25",
                "type": "sankey"
            },
            "zyjg": "科学技术部高技术研究发展中心"
        },
        {
            "title": {
                show: true,
                text: "{a|项目管理专业机构}{a|国家重点研发计划重点专项}{a|国家科学数据中心} ",
                textStyle: {
                    rich: {
                        a: {
                            // display:'block',
                            // 'margin-right':'100px',
                            width: 645,
                            'text-align': 'left',
                            'float': 'left',
                            fontWeight: 'bold',
                            fontSize: 24,
                        },
                    },
                },
                left:25,
                top: 45,
            },
            toolbox: {
                show: true,
                feature: {
                    saveAsImage: {
                        show: true,
                        name: "专业机构-项目-数据中心桑基图"
                    }
                }
            },
            "series": {
                "data": [
                    {
                        "depth": 0,
                        "itemStyle": {
                            "color": "#98206f"
                        },
                        "name": "中国农村技术开发中心",
                        "value": 234
                    },
                    {
                        "depth": 1,
                        "itemStyle": {
                            "color": "#b7b7b7"
                        },
                        "name": "畜禽重大疫病防控与高效安全养殖综合技术研发",
                        "val": 63,
                        "value": "63(26.92%)"
                    },
                    {
                        "depth": 1,
                        "itemStyle": {
                            "color": "#588638"
                        },
                        "name": "七大农作物育种",
                        "val": 50,
                        "value": "50(21.37%)"
                    },
                    {
                        "depth": 1,
                        "itemStyle": {
                            "color": "#edad81"
                        },
                        "name": "智能农机装备",
                        "val": 49,
                        "value": "49(20.94%)"
                    },
                    {
                        "depth": 1,
                        "itemStyle": {
                            "color": "#f7d368"
                        },
                        "name": "现代食品加工及粮食收储运技术与装备",
                        "val": 44,
                        "value": "44(18.80%)"
                    },
                    {
                        "depth": 1,
                        "itemStyle": {
                            "color": "#747474"
                        },
                        "name": "林业资源培育及高效利用技术创新",
                        "val": 26,
                        "value": "26(11.11%)"
                    },
                    {
                        "depth": 1,
                        "itemStyle": {
                            "color": "#a5a5a5"
                        },
                        "name": "公共安全风险防控与应急技术装备",
                        "val": 1,
                        "value": "1(0.43%)"
                    },
                    {
                        "depth": 1,
                        "itemStyle": {
                            "color": "#ad841f"
                        },
                        "name": "蓝色粮仓科技创新",
                        "val": 1,
                        "value": "1(0.43%)"
                    },
                    {
                        "depth": 2,
                        "itemStyle": {
                            "color": "#b3af76"
                        },
                        "name": "国家农业科学数据中心",
                        "val": 205,
                        "value": "205(87.61%)"
                    },
                    {
                        "depth": 2,
                        "itemStyle": {
                            "color": "#e0d341"
                        },
                        "name": "国家林业和草原科学数据中心",
                        "val": 26,
                        "value": "26(11.11%)"
                    },
                    {
                        "depth": 2,
                        "itemStyle": {
                            "color": "#86aba0"
                        },
                        "name": "国家微生物科学数据中心",
                        "val": 3,
                        "value": "3(1.28%)"
                    }
                ],
                "emphasis": {
                    "focus": "adjacency"
                },
                "itemStyle": {
                    "borderWidth": 20
                },
                "label": {
                    "fontWeight": "bold",
                    "textBorderWidth": 0.5,
                    "fontSize": 20,
                    "lineHeight": 80,
                    "overflow": "break",
                    "width": 600
                },
                "layout": "none",
                "layoutIterations": 0,
                "levels": [
                    {
                        "depth": 0,
                        "label": {
                            "formatter": "{b}: {@value}"
                        }
                    },
                    {
                        "depth": 1,
                        "label": {
                            "formatter": "{b}: {@value}"
                        }
                    },
                    {
                        "depth": 2,
                        "label": {
                            "formatter": "{b}: {@value}"
                        }
                    }
                ],
                "lineStyle": {
                    "color": "gradient",
                    "curveness": 0,
                    "opacity": 0.3
                },
                "links": [
                    {
                        "source": "中国农村技术开发中心",
                        "target": "公共安全风险防控与应急技术装备",
                        "value": 1
                    },
                    {
                        "source": "公共安全风险防控与应急技术装备",
                        "target": "国家微生物科学数据中心",
                        "value": 1
                    },
                    {
                        "source": "中国农村技术开发中心",
                        "target": "蓝色粮仓科技创新",
                        "value": 1
                    },
                    {
                        "source": "蓝色粮仓科技创新",
                        "target": "国家农业科学数据中心",
                        "value": 1
                    },
                    {
                        "source": "现代食品加工及粮食收储运技术与装备",
                        "target": "国家微生物科学数据中心",
                        "value": 2
                    },
                    {
                        "source": "中国农村技术开发中心",
                        "target": "林业资源培育及高效利用技术创新",
                        "value": 26
                    },
                    {
                        "source": "林业资源培育及高效利用技术创新",
                        "target": "国家林业和草原科学数据中心",
                        "value": 26
                    },
                    {
                        "source": "中国农村技术开发中心",
                        "target": "现代食品加工及粮食收储运技术与装备",
                        "value": 44
                    },
                    {
                        "source": "现代食品加工及粮食收储运技术与装备",
                        "target": "国家农业科学数据中心",
                        "value": 42
                    },
                    {
                        "source": "中国农村技术开发中心",
                        "target": "智能农机装备",
                        "value": 49
                    },
                    {
                        "source": "智能农机装备",
                        "target": "国家农业科学数据中心",
                        "value": 49
                    },
                    {
                        "source": "中国农村技术开发中心",
                        "target": "七大农作物育种",
                        "value": 50
                    },
                    {
                        "source": "七大农作物育种",
                        "target": "国家农业科学数据中心",
                        "value": 50
                    },
                    {
                        "source": "中国农村技术开发中心",
                        "target": "畜禽重大疫病防控与高效安全养殖综合技术研发",
                        "value": 63
                    },
                    {
                        "source": "畜禽重大疫病防控与高效安全养殖综合技术研发",
                        "target": "国家农业科学数据中心",
                        "value": 63
                    }
                ],
                "nodeGap": 30,
                "nodeWidth": 30,
                "right": "25%",
                top: "7%",
                bottom: "4%",
                left:"25",
                "type": "sankey"
            },
            "zyjg": "中国农村技术开发中心"
        },
        {
            "title": {
                show: true,
                text: "{a|项目管理专业机构}{a|国家重点研发计划重点专项}{a|国家科学数据中心} ",
                textStyle: {
                    rich: {
                        a: {
                            // display:'block',
                            // 'margin-right':'100px',
                            width: 645,
                            'text-align': 'left',
                            'float': 'left',
                            fontWeight: 'bold',
                            fontSize: 24,
                        },
                    },
                },
                left:25,
                top: 45,
            },
            toolbox: {
                show: true,
                feature: {
                    saveAsImage: {
                        show: true,
                        name: "专业机构-项目-数据中心桑基图"
                    }
                }
            },
            "series": {
                "data": [
                    {
                        "depth": 0,
                        "itemStyle": {
                            "color": "#97647d"
                        },
                        "name": "中国21世纪议程管理中心",
                        "value": 406
                    },
                    {
                        "depth": 1,
                        "itemStyle": {
                            "color": "#947226"
                        },
                        "name": "国家质量基础的共性技术研究与应用",
                        "val": 102,
                        "value": "102(25.12%)"
                    },
                    {
                        "depth": 1,
                        "itemStyle": {
                            "color": "#e89359"
                        },
                        "name": "典型脆弱生态修复与保护研究",
                        "val": 64,
                        "value": "64(15.76%)"
                    },
                    {
                        "depth": 1,
                        "itemStyle": {
                            "color": "#325698"
                        },
                        "name": "水资源高效开发利用",
                        "val": 59,
                        "value": "59(14.53%)"
                    },
                    {
                        "depth": 1,
                        "itemStyle": {
                            "color": "#a5a5a5"
                        },
                        "name": "公共安全风险防控与应急技术装备",
                        "val": 49,
                        "value": "49(12.07%)"
                    },
                    {
                        "depth": 1,
                        "itemStyle": {
                            "color": "#eaa06d"
                        },
                        "name": "海洋环境安全保障",
                        "val": 32,
                        "value": "32(7.88%)"
                    },
                    {
                        "depth": 1,
                        "itemStyle": {
                            "color": "#f6cd50"
                        },
                        "name": "绿色建筑及建筑工业化",
                        "val": 31,
                        "value": "31(7.64%)"
                    },
                    {
                        "depth": 1,
                        "itemStyle": {
                            "color": "#f6cd50"
                        },
                        "name": "深地资源勘查开采",
                        "val": 26,
                        "value": "26(6.40%)"
                    },
                    {
                        "depth": 1,
                        "itemStyle": {
                            "color": "#2a68a0"
                        },
                        "name": "大气污染成因与控制技术研究",
                        "val": 21,
                        "value": "21(5.17%)"
                    },
                    {
                        "depth": 1,
                        "itemStyle": {
                            "color": "#aac8e4"
                        },
                        "name": "深海关键技术与装备",
                        "val": 9,
                        "value": "9(2.22%)"
                    },
                    {
                        "depth": 1,
                        "itemStyle": {
                            "color": "#b3d29e"
                        },
                        "name": "重大自然灾害监测预警与防范",
                        "val": 7,
                        "value": "7(1.72%)"
                    },
                    {
                        "depth": 1,
                        "itemStyle": {
                            "color": "#6ea647"
                        },
                        "name": "政府间国际科技创新合作重点专项",
                        "val": 2,
                        "value": "2(0.49%)"
                    },
                    {
                        "depth": 1,
                        "itemStyle": {
                            "color": "#c1c0c0"
                        },
                        "name": "地球观测与导航",
                        "val": 1,
                        "value": "1(0.25%)"
                    },
                    {
                        "depth": 1,
                        "itemStyle": {
                            "color": "#b05322"
                        },
                        "name": "固废资源化",
                        "val": 1,
                        "value": "1(0.25%)"
                    },
                    {
                        "depth": 1,
                        "itemStyle": {
                            "color": "#2a4c85"
                        },
                        "name": "全球变化及应对",
                        "val": 1,
                        "value": "1(0.25%)"
                    },
                    {
                        "depth": 1,
                        "itemStyle": {
                            "color": "#7b5f27"
                        },
                        "name": "高性能计算",
                        "val": 1,
                        "value": "1(0.25%)"
                    },
                    {
                        "depth": 2,
                        "itemStyle": {
                            "color": "#afb5ac"
                        },
                        "name": "国家计量科学数据中心",
                        "val": 114,
                        "value": "114(28.08%)"
                    },
                    {
                        "depth": 2,
                        "itemStyle": {
                            "color": "#757571"
                        },
                        "name": "国家基础学科公共科学数据中心",
                        "val": 104,
                        "value": "104(25.62%)"
                    },
                    {
                        "depth": 2,
                        "itemStyle": {
                            "color": "#acc3b6"
                        },
                        "name": "国家生态科学数据中心",
                        "val": 50,
                        "value": "50(12.32%)"
                    },
                    {
                        "depth": 2,
                        "itemStyle": {
                            "color": "#83774e"
                        },
                        "name": "国家海洋科学数据中心",
                        "val": 44,
                        "value": "44(10.84%)"
                    },
                    {
                        "depth": 2,
                        "itemStyle": {
                            "color": "#6b7257"
                        },
                        "name": "国家地球系统科学数据中心",
                        "val": 38,
                        "value": "38(9.36%)"
                    },
                    {
                        "depth": 2,
                        "itemStyle": {
                            "color": "#b3af76"
                        },
                        "name": "国家农业科学数据中心",
                        "val": 11,
                        "value": "11(2.71%)"
                    },
                    {
                        "depth": 2,
                        "itemStyle": {
                            "color": "#e0d341"
                        },
                        "name": "国家林业和草原科学数据中心",
                        "val": 9,
                        "value": "9(2.22%)"
                    },
                    {
                        "depth": 2,
                        "itemStyle": {
                            "color": "#d3c755"
                        },
                        "name": "国家地震科学数据中心",
                        "val": 7,
                        "value": "7(1.72%)"
                    },
                    {
                        "depth": 2,
                        "itemStyle": {
                            "color": "#e5e3cf"
                        },
                        "name": "国家气象科学数据中心",
                        "val": 7,
                        "value": "7(1.72%)"
                    },
                    {
                        "depth": 2,
                        "itemStyle": {
                            "color": "#3b6c51"
                        },
                        "name": "国家青藏高原科学数据中心",
                        "val": 5,
                        "value": "5(1.23%)"
                    },
                    {
                        "depth": 2,
                        "itemStyle": {
                            "color": "#495946"
                        },
                        "name": "国家冰川冻土沙漠科学数据中心",
                        "val": 5,
                        "value": "5(1.23%)"
                    },
                    {
                        "depth": 2,
                        "itemStyle": {
                            "color": "#a7ae8e"
                        },
                        "name": "国家人口健康科学数据中心",
                        "val": 4,
                        "value": "4(0.99%)"
                    },
                    {
                        "depth": 2,
                        "itemStyle": {
                            "color": "#8dc3aa"
                        },
                        "name": "国家对地观测科学数据中心",
                        "val": 4,
                        "value": "4(0.99%)"
                    },
                    {
                        "depth": 2,
                        "itemStyle": {
                            "color": "#6b7257"
                        },
                        "name": "国家极地科学数据中心",
                        "val": 1,
                        "value": "1(0.25%)"
                    },
                    {
                        "depth": 2,
                        "itemStyle": {
                            "color": "#86aba0"
                        },
                        "name": "国家微生物科学数据中心",
                        "val": 1,
                        "value": "1(0.25%)"
                    },
                    {
                        "depth": 2,
                        "itemStyle": {
                            "color": "#86aba0"
                        },
                        "name": "国家材料腐蚀与防护科学数据中心",
                        "val": 1,
                        "value": "1(0.25%)"
                    },
                    {
                        "depth": 2,
                        "itemStyle": {
                            "color": "#637270"
                        },
                        "name": "国家基因组科学数据中心",
                        "val": 1,
                        "value": "1(0.25%)"
                    }
                ],
                "emphasis": {
                    "focus": "adjacency"
                },
                "itemStyle": {
                    "borderWidth": 20
                },
                "label": {
                    "fontWeight": "bold",
                    "textBorderWidth": 0.5,
                    "fontSize": 20,
                    "lineHeight": 80,
                    "overflow": "break",
                    "width": 600
                },
                "layout": "none",
                "layoutIterations": 0,
                "levels": [
                    {
                        "depth": 0,
                        "label": {
                            "formatter": "{b}: {@value}"
                        }
                    },
                    {
                        "depth": 1,
                        "label": {
                            "formatter": "{b}: {@value}"
                        }
                    },
                    {
                        "depth": 2,
                        "label": {
                            "formatter": "{b}: {@value}"
                        }
                    }
                ],
                "lineStyle": {
                    "color": "gradient",
                    "curveness": 0,
                    "opacity": 0.3
                },
                "links": [
                    {
                        "source": "水资源高效开发利用",
                        "target": "国家林业和草原科学数据中心",
                        "value": 1
                    },
                    {
                        "source": "大气污染成因与控制技术研究",
                        "target": "国家农业科学数据中心",
                        "value": 1
                    },
                    {
                        "source": "海洋环境安全保障",
                        "target": "国家极地科学数据中心",
                        "value": 1
                    },
                    {
                        "source": "国家质量基础的共性技术研究与应用",
                        "target": "国家微生物科学数据中心",
                        "value": 1
                    },
                    {
                        "source": "重大自然灾害监测预警与防范",
                        "target": "国家青藏高原科学数据中心",
                        "value": 1
                    },
                    {
                        "source": "典型脆弱生态修复与保护研究",
                        "target": "国家海洋科学数据中心",
                        "value": 1
                    },
                    {
                        "source": "典型脆弱生态修复与保护研究",
                        "target": "国家冰川冻土沙漠科学数据中心",
                        "value": 1
                    },
                    {
                        "source": "深地资源勘查开采",
                        "target": "国家生态科学数据中心",
                        "value": 1
                    },
                    {
                        "source": "深海关键技术与装备",
                        "target": "国家基础学科公共科学数据中心",
                        "value": 1
                    },
                    {
                        "source": "政府间国际科技创新合作重点专项",
                        "target": "国家海洋科学数据中心",
                        "value": 1
                    },
                    {
                        "source": "水资源高效开发利用",
                        "target": "国家地震科学数据中心",
                        "value": 1
                    },
                    {
                        "source": "水资源高效开发利用",
                        "target": "国家材料腐蚀与防护科学数据中心",
                        "value": 1
                    },
                    {
                        "source": "中国21世纪议程管理中心",
                        "target": "地球观测与导航",
                        "value": 1
                    },
                    {
                        "source": "地球观测与导航",
                        "target": "国家海洋科学数据中心",
                        "value": 1
                    },
                    {
                        "source": "国家质量基础的共性技术研究与应用",
                        "target": "国家农业科学数据中心",
                        "value": 1
                    },
                    {
                        "source": "中国21世纪议程管理中心",
                        "target": "固废资源化",
                        "value": 1
                    },
                    {
                        "source": "固废资源化",
                        "target": "国家基础学科公共科学数据中心",
                        "value": 1
                    },
                    {
                        "source": "大气污染成因与控制技术研究",
                        "target": "国家地球系统科学数据中心",
                        "value": 1
                    },
                    {
                        "source": "中国21世纪议程管理中心",
                        "target": "全球变化及应对",
                        "value": 1
                    },
                    {
                        "source": "全球变化及应对",
                        "target": "国家冰川冻土沙漠科学数据中心",
                        "value": 1
                    },
                    {
                        "source": "大气污染成因与控制技术研究",
                        "target": "国家计量科学数据中心",
                        "value": 1
                    },
                    {
                        "source": "海洋环境安全保障",
                        "target": "国家计量科学数据中心",
                        "value": 1
                    },
                    {
                        "source": "重大自然灾害监测预警与防范",
                        "target": "国家地球系统科学数据中心",
                        "value": 1
                    },
                    {
                        "source": "水资源高效开发利用",
                        "target": "国家气象科学数据中心",
                        "value": 1
                    },
                    {
                        "source": "水资源高效开发利用",
                        "target": "国家计量科学数据中心",
                        "value": 1
                    },
                    {
                        "source": "典型脆弱生态修复与保护研究",
                        "target": "国家青藏高原科学数据中心",
                        "value": 1
                    },
                    {
                        "source": "水资源高效开发利用",
                        "target": "国家海洋科学数据中心",
                        "value": 1
                    },
                    {
                        "source": "中国21世纪议程管理中心",
                        "target": "高性能计算",
                        "value": 1
                    },
                    {
                        "source": "高性能计算",
                        "target": "国家海洋科学数据中心",
                        "value": 1
                    },
                    {
                        "source": "国家质量基础的共性技术研究与应用",
                        "target": "国家基因组科学数据中心",
                        "value": 1
                    },
                    {
                        "source": "中国21世纪议程管理中心",
                        "target": "政府间国际科技创新合作重点专项",
                        "value": 2
                    },
                    {
                        "source": "政府间国际科技创新合作重点专项",
                        "target": "国家青藏高原科学数据中心",
                        "value": 1
                    },
                    {
                        "source": "公共安全风险防控与应急技术装备",
                        "target": "国家地震科学数据中心",
                        "value": 1
                    },
                    {
                        "source": "重大自然灾害监测预警与防范",
                        "target": "国家对地观测科学数据中心",
                        "value": 1
                    },
                    {
                        "source": "深海关键技术与装备",
                        "target": "国家冰川冻土沙漠科学数据中心",
                        "value": 1
                    },
                    {
                        "source": "大气污染成因与控制技术研究",
                        "target": "国家人口健康科学数据中心",
                        "value": 2
                    },
                    {
                        "source": "国家质量基础的共性技术研究与应用",
                        "target": "国家海洋科学数据中心",
                        "value": 2
                    },
                    {
                        "source": "重大自然灾害监测预警与防范",
                        "target": "国家气象科学数据中心",
                        "value": 2
                    },
                    {
                        "source": "深地资源勘查开采",
                        "target": "国家青藏高原科学数据中心",
                        "value": 2
                    },
                    {
                        "source": "水资源高效开发利用",
                        "target": "国家生态科学数据中心",
                        "value": 2
                    },
                    {
                        "source": "公共安全风险防控与应急技术装备",
                        "target": "国家农业科学数据中心",
                        "value": 2
                    },
                    {
                        "source": "公共安全风险防控与应急技术装备",
                        "target": "国家人口健康科学数据中心",
                        "value": 2
                    },
                    {
                        "source": "水资源高效开发利用",
                        "target": "国家冰川冻土沙漠科学数据中心",
                        "value": 2
                    },
                    {
                        "source": "中国21世纪议程管理中心",
                        "target": "重大自然灾害监测预警与防范",
                        "value": 7
                    },
                    {
                        "source": "重大自然灾害监测预警与防范",
                        "target": "国家地震科学数据中心",
                        "value": 2
                    },
                    {
                        "source": "典型脆弱生态修复与保护研究",
                        "target": "国家基础学科公共科学数据中心",
                        "value": 2
                    },
                    {
                        "source": "典型脆弱生态修复与保护研究",
                        "target": "国家农业科学数据中心",
                        "value": 2
                    },
                    {
                        "source": "深地资源勘查开采",
                        "target": "国家地震科学数据中心",
                        "value": 3
                    },
                    {
                        "source": "深地资源勘查开采",
                        "target": "国家对地观测科学数据中心",
                        "value": 3
                    },
                    {
                        "source": "大气污染成因与控制技术研究",
                        "target": "国家气象科学数据中心",
                        "value": 4
                    },
                    {
                        "source": "水资源高效开发利用",
                        "target": "国家农业科学数据中心",
                        "value": 5
                    },
                    {
                        "source": "大气污染成因与控制技术研究",
                        "target": "国家生态科学数据中心",
                        "value": 5
                    },
                    {
                        "source": "公共安全风险防控与应急技术装备",
                        "target": "国家计量科学数据中心",
                        "value": 6
                    },
                    {
                        "source": "典型脆弱生态修复与保护研究",
                        "target": "国家地球系统科学数据中心",
                        "value": 7
                    },
                    {
                        "source": "中国21世纪议程管理中心",
                        "target": "大气污染成因与控制技术研究",
                        "value": 21
                    },
                    {
                        "source": "大气污染成因与控制技术研究",
                        "target": "国家基础学科公共科学数据中心",
                        "value": 7
                    },
                    {
                        "source": "中国21世纪议程管理中心",
                        "target": "深海关键技术与装备",
                        "value": 9
                    },
                    {
                        "source": "深海关键技术与装备",
                        "target": "国家海洋科学数据中心",
                        "value": 7
                    },
                    {
                        "source": "深地资源勘查开采",
                        "target": "国家基础学科公共科学数据中心",
                        "value": 8
                    },
                    {
                        "source": "典型脆弱生态修复与保护研究",
                        "target": "国家林业和草原科学数据中心",
                        "value": 8
                    },
                    {
                        "source": "绿色建筑及建筑工业化",
                        "target": "国家计量科学数据中心",
                        "value": 8
                    },
                    {
                        "source": "中国21世纪议程管理中心",
                        "target": "深地资源勘查开采",
                        "value": 26
                    },
                    {
                        "source": "深地资源勘查开采",
                        "target": "国家地球系统科学数据中心",
                        "value": 9
                    },
                    {
                        "source": "水资源高效开发利用",
                        "target": "国家地球系统科学数据中心",
                        "value": 20
                    },
                    {
                        "source": "中国21世纪议程管理中心",
                        "target": "绿色建筑及建筑工业化",
                        "value": 31
                    },
                    {
                        "source": "绿色建筑及建筑工业化",
                        "target": "国家基础学科公共科学数据中心",
                        "value": 23
                    },
                    {
                        "source": "中国21世纪议程管理中心",
                        "target": "水资源高效开发利用",
                        "value": 59
                    },
                    {
                        "source": "水资源高效开发利用",
                        "target": "国家基础学科公共科学数据中心",
                        "value": 24
                    },
                    {
                        "source": "中国21世纪议程管理中心",
                        "target": "海洋环境安全保障",
                        "value": 32
                    },
                    {
                        "source": "海洋环境安全保障",
                        "target": "国家海洋科学数据中心",
                        "value": 30
                    },
                    {
                        "source": "中国21世纪议程管理中心",
                        "target": "公共安全风险防控与应急技术装备",
                        "value": 49
                    },
                    {
                        "source": "公共安全风险防控与应急技术装备",
                        "target": "国家基础学科公共科学数据中心",
                        "value": 38
                    },
                    {
                        "source": "中国21世纪议程管理中心",
                        "target": "典型脆弱生态修复与保护研究",
                        "value": 64
                    },
                    {
                        "source": "典型脆弱生态修复与保护研究",
                        "target": "国家生态科学数据中心",
                        "value": 42
                    },
                    {
                        "source": "中国21世纪议程管理中心",
                        "target": "国家质量基础的共性技术研究与应用",
                        "value": 102
                    },
                    {
                        "source": "国家质量基础的共性技术研究与应用",
                        "target": "国家计量科学数据中心",
                        "value": 97
                    }
                ],
                "nodeGap": 30,
                "nodeWidth": 30,
                "right": "25%",
                top: "7%",
                bottom: "4%",
                left:"25",
                "type": "sankey"
            },
            "zyjg": "中国21世纪议程管理中心"
        },
        {
            "title": {
                show: true,
                text: "{a|项目管理专业机构}{a|国家重点研发计划重点专项}{a|国家科学数据中心} ",
                textStyle: {
                    rich: {
                        a: {
                            // display:'block',
                            // 'margin-right':'100px',
                            width: 645,
                            'text-align': 'left',
                            'float': 'left',
                            fontWeight: 'bold',
                            fontSize: 24,
                        },
                    },
                },
                left:25,
                top: 45,
            },
            toolbox: {
                show: true,
                feature: {
                    saveAsImage: {
                        show: true,
                        name: "专业机构-项目-数据中心桑基图"
                    }
                }
            },
            "series": {
                "data": [
                    {
                        "depth": 0,
                        "itemStyle": {
                            "color": "#623340"
                        },
                        "name": "农业农村部科技发展中心",
                        "value": 124
                    },
                    {
                        "depth": 1,
                        "itemStyle": {
                            "color": "#8ca5d3"
                        },
                        "name": "化学肥料和农药减施增效综合技术研发",
                        "val": 49,
                        "value": "49(39.52%)"
                    },
                    {
                        "depth": 1,
                        "itemStyle": {
                            "color": "#1d3661"
                        },
                        "name": "粮食丰产增效科技创新",
                        "val": 39,
                        "value": "39(31.45%)"
                    },
                    {
                        "depth": 1,
                        "itemStyle": {
                            "color": "#1c4c75"
                        },
                        "name": "农业面源和重金属污染农田综合防治与修复技术研发",
                        "val": 35,
                        "value": "35(28.23%)"
                    },
                    {
                        "depth": 1,
                        "itemStyle": {
                            "color": "#c9c9c9"
                        },
                        "name": "主要经济作物优质高产与产业提质增效科技创新",
                        "val": 1,
                        "value": "1(0.81%)"
                    },
                    {
                        "depth": 2,
                        "itemStyle": {
                            "color": "#b3af76"
                        },
                        "name": "国家农业科学数据中心",
                        "val": 123,
                        "value": "123(99.19%)"
                    },
                    {
                        "depth": 2,
                        "itemStyle": {
                            "color": "#86aba0"
                        },
                        "name": "国家微生物科学数据中心",
                        "val": 1,
                        "value": "1(0.81%)"
                    }
                ],
                "emphasis": {
                    "focus": "adjacency"
                },
                "itemStyle": {
                    "borderWidth": 20
                },
                "label": {
                    "fontWeight": "bold",
                    "textBorderWidth": 0.5,
                    "fontSize": 20,
                    "lineHeight": 80,
                    "overflow": "break",
                    "width": 600
                },
                "layout": "none",
                "layoutIterations": 0,
                "levels": [
                    {
                        "depth": 0,
                        "label": {
                            "formatter": "{b}: {@value}"
                        }
                    },
                    {
                        "depth": 1,
                        "label": {
                            "formatter": "{b}: {@value}"
                        }
                    },
                    {
                        "depth": 2,
                        "label": {
                            "formatter": "{b}: {@value}"
                        }
                    }
                ],
                "lineStyle": {
                    "color": "gradient",
                    "curveness": 0,
                    "opacity": 0.3
                },
                "links": [
                    {
                        "source": "化学肥料和农药减施增效综合技术研发",
                        "target": "国家微生物科学数据中心",
                        "value": 1
                    },
                    {
                        "source": "农业农村部科技发展中心",
                        "target": "主要经济作物优质高产与产业提质增效科技创新",
                        "value": 1
                    },
                    {
                        "source": "主要经济作物优质高产与产业提质增效科技创新",
                        "target": "国家农业科学数据中心",
                        "value": 1
                    },
                    {
                        "source": "农业农村部科技发展中心",
                        "target": "农业面源和重金属污染农田综合防治与修复技术研发",
                        "value": 35
                    },
                    {
                        "source": "农业面源和重金属污染农田综合防治与修复技术研发",
                        "target": "国家农业科学数据中心",
                        "value": 35
                    },
                    {
                        "source": "农业农村部科技发展中心",
                        "target": "粮食丰产增效科技创新",
                        "value": 39
                    },
                    {
                        "source": "粮食丰产增效科技创新",
                        "target": "国家农业科学数据中心",
                        "value": 39
                    },
                    {
                        "source": "农业农村部科技发展中心",
                        "target": "化学肥料和农药减施增效综合技术研发",
                        "value": 49
                    },
                    {
                        "source": "化学肥料和农药减施增效综合技术研发",
                        "target": "国家农业科学数据中心",
                        "value": 48
                    }
                ],
                "nodeGap": 30,
                "nodeWidth": 30,
                "right": "25%",
                top: "7%",
                bottom: "4%",
                left:"25",
                "type": "sankey"
            },
            "zyjg": "农业农村部科技发展中心"
        },
        {
            "title": {
                show: true,
                text: "{a|项目管理专业机构}{a|国家重点研发计划重点专项}{a|国家科学数据中心} ",
                textStyle: {
                    rich: {
                        a: {
                            // display:'block',
                            // 'margin-right':'100px',
                            width: 645,
                            'text-align': 'left',
                            'float': 'left',
                            fontWeight: 'bold',
                            fontSize: 24,
                        },
                    },
                },
                left:25,
                top: 45,
            },
            toolbox: {
                show: true,
                feature: {
                    saveAsImage: {
                        show: true,
                        name: "专业机构-项目-数据中心桑基图"
                    }
                }
            },
            "series": {
                "data": [
                    {
                        "depth": 0,
                        "itemStyle": {
                            "color": "#6f4c4d"
                        },
                        "name": "中国生物技术发展中心",
                        "value": 1225
                    },
                    {
                        "depth": 1,
                        "itemStyle": {
                            "color": "#406cb4"
                        },
                        "name": "数字诊疗装备研发",
                        "val": 205,
                        "value": "205(16.73%)"
                    },
                    {
                        "depth": 1,
                        "itemStyle": {
                            "color": "#e37933"
                        },
                        "name": "重大慢性非传染性疾病防控研究",
                        "val": 165,
                        "value": "165(13.47%)"
                    },
                    {
                        "depth": 1,
                        "itemStyle": {
                            "color": "#f5ba1a"
                        },
                        "name": "干细胞及转化研究",
                        "val": 137,
                        "value": "137(11.18%)"
                    },
                    {
                        "depth": 1,
                        "itemStyle": {
                            "color": "#5893c9"
                        },
                        "name": "中医药现代化研究",
                        "val": 131,
                        "value": "131(10.69%)"
                    },
                    {
                        "depth": 1,
                        "itemStyle": {
                            "color": "#254273"
                        },
                        "name": "合成生物学",
                        "val": 113,
                        "value": "113(9.22%)"
                    },
                    {
                        "depth": 1,
                        "itemStyle": {
                            "color": "#a5a5a5"
                        },
                        "name": "公共安全风险防控与应急技术装备",
                        "val": 100,
                        "value": "100(8.16%)"
                    },
                    {
                        "depth": 1,
                        "itemStyle": {
                            "color": "#245a8a"
                        },
                        "name": "主动健康和老龄化科技应对",
                        "val": 89,
                        "value": "89(7.27%)"
                    },
                    {
                        "depth": 1,
                        "itemStyle": {
                            "color": "#426631"
                        },
                        "name": "生物医用材料研发与组织器官修复替代",
                        "val": 77,
                        "value": "77(6.29%)"
                    },
                    {
                        "depth": 1,
                        "itemStyle": {
                            "color": "#87b966"
                        },
                        "name": "食品安全关键技术研发",
                        "val": 61,
                        "value": "61(4.98%)"
                    },
                    {
                        "depth": 1,
                        "itemStyle": {
                            "color": "#c9c9c9"
                        },
                        "name": "生物安全关键技术研发",
                        "val": 44,
                        "value": "44(3.59%)"
                    },
                    {
                        "depth": 1,
                        "itemStyle": {
                            "color": "#803c20"
                        },
                        "name": "绿色生物制造",
                        "val": 38,
                        "value": "38(3.10%)"
                    },
                    {
                        "depth": 1,
                        "itemStyle": {
                            "color": "#9fb4db"
                        },
                        "name": "常见多发病防治研究",
                        "val": 19,
                        "value": "19(1.55%)"
                    },
                    {
                        "depth": 1,
                        "itemStyle": {
                            "color": "#f0ba96"
                        },
                        "name": "干细胞研究与器官修复",
                        "val": 15,
                        "value": "15(1.22%)"
                    },
                    {
                        "depth": 1,
                        "itemStyle": {
                            "color": "#d2d2d2"
                        },
                        "name": "脑科学与类脑研究",
                        "val": 13,
                        "value": "13(1.06%)"
                    },
                    {
                        "depth": 1,
                        "itemStyle": {
                            "color": "#a5a5a5"
                        },
                        "name": "诊疗装备与生物医用材料",
                        "val": 6,
                        "value": "6(0.49%)"
                    },
                    {
                        "depth": 1,
                        "itemStyle": {
                            "color": "#e37933"
                        },
                        "name": "生物与信息融合（BT与IT融合）",
                        "val": 6,
                        "value": "6(0.49%)"
                    },
                    {
                        "depth": 1,
                        "itemStyle": {
                            "color": "#f5ba1a"
                        },
                        "name": "颠覆性技术（生物技术领域）",
                        "val": 5,
                        "value": "5(0.41%)"
                    },
                    {
                        "depth": 1,
                        "itemStyle": {
                            "color": "#3376b5"
                        },
                        "name": "生物安全关键技术研究",
                        "val": 1,
                        "value": "1(0.08%)"
                    },
                    {
                        "depth": 2,
                        "itemStyle": {
                            "color": "#a7ae8e"
                        },
                        "name": "国家人口健康科学数据中心",
                        "val": 773,
                        "value": "773(63.10%)"
                    },
                    {
                        "depth": 2,
                        "itemStyle": {
                            "color": "#86aba0"
                        },
                        "name": "国家微生物科学数据中心",
                        "val": 283,
                        "value": "283(23.10%)"
                    },
                    {
                        "depth": 2,
                        "itemStyle": {
                            "color": "#637270"
                        },
                        "name": "国家基因组科学数据中心",
                        "val": 93,
                        "value": "93(7.59%)"
                    },
                    {
                        "depth": 2,
                        "itemStyle": {
                            "color": "#afb5ac"
                        },
                        "name": "国家计量科学数据中心",
                        "val": 27,
                        "value": "27(2.20%)"
                    },
                    {
                        "depth": 2,
                        "itemStyle": {
                            "color": "#757571"
                        },
                        "name": "国家基础学科公共科学数据中心",
                        "val": 18,
                        "value": "18(1.47%)"
                    },
                    {
                        "depth": 2,
                        "itemStyle": {
                            "color": "#b3af76"
                        },
                        "name": "国家农业科学数据中心",
                        "val": 17,
                        "value": "17(1.39%)"
                    },
                    {
                        "depth": 2,
                        "itemStyle": {
                            "color": "#86aba0"
                        },
                        "name": "国家材料腐蚀与防护科学数据中心",
                        "val": 4,
                        "value": "4(0.33%)"
                    },
                    {
                        "depth": 2,
                        "itemStyle": {
                            "color": "#006b5c"
                        },
                        "name": "国家高能物理科学数据中心",
                        "val": 4,
                        "value": "4(0.33%)"
                    },
                    {
                        "depth": 2,
                        "itemStyle": {
                            "color": "#3b6c51"
                        },
                        "name": "国家青藏高原科学数据中心",
                        "val": 3,
                        "value": "3(0.24%)"
                    },
                    {
                        "depth": 2,
                        "itemStyle": {
                            "color": "#e0d341"
                        },
                        "name": "国家林业和草原科学数据中心",
                        "val": 1,
                        "value": "1(0.08%)"
                    },
                    {
                        "depth": 2,
                        "itemStyle": {
                            "color": "#6b7257"
                        },
                        "name": "国家地球系统科学数据中心",
                        "val": 1,
                        "value": "1(0.08%)"
                    },
                    {
                        "depth": 2,
                        "itemStyle": {
                            "color": "#495946"
                        },
                        "name": "国家冰川冻土沙漠科学数据中心",
                        "val": 1,
                        "value": "1(0.08%)"
                    }
                ],
                "emphasis": {
                    "focus": "adjacency"
                },
                "itemStyle": {
                    "borderWidth": 20
                },
                "label": {
                    "fontWeight": "bold",
                    "textBorderWidth": 0.5,
                    "fontSize": 20,
                    "lineHeight": 80,
                    "overflow": "break",
                    "width": 600
                },
                "layout": "none",
                "layoutIterations": 0,
                "levels": [
                    {
                        "depth": 0,
                        "label": {
                            "formatter": "{b}: {@value}"
                        }
                    },
                    {
                        "depth": 1,
                        "label": {
                            "formatter": "{b}: {@value}"
                        }
                    },
                    {
                        "depth": 2,
                        "label": {
                            "formatter": "{b}: {@value}"
                        }
                    }
                ],
                "lineStyle": {
                    "color": "gradient",
                    "curveness": 0,
                    "opacity": 0.3
                },
                "links": [
                    {
                        "source": "生物安全关键技术研发",
                        "target": "国家林业和草原科学数据中心",
                        "value": 1
                    },
                    {
                        "source": "干细胞及转化研究",
                        "target": "国家计量科学数据中心",
                        "value": 1
                    },
                    {
                        "source": "数字诊疗装备研发",
                        "target": "国家基因组科学数据中心",
                        "value": 1
                    },
                    {
                        "source": "生物安全关键技术研发",
                        "target": "国家地球系统科学数据中心",
                        "value": 1
                    },
                    {
                        "source": "数字诊疗装备研发",
                        "target": "国家冰川冻土沙漠科学数据中心",
                        "value": 1
                    },
                    {
                        "source": "绿色生物制造",
                        "target": "国家农业科学数据中心",
                        "value": 1
                    },
                    {
                        "source": "生物安全关键技术研发",
                        "target": "国家基因组科学数据中心",
                        "value": 1
                    },
                    {
                        "source": "中医药现代化研究",
                        "target": "国家农业科学数据中心",
                        "value": 1
                    },
                    {
                        "source": "主动健康和老龄化科技应对",
                        "target": "国家计量科学数据中心",
                        "value": 1
                    },
                    {
                        "source": "主动健康和老龄化科技应对",
                        "target": "国家青藏高原科学数据中心",
                        "value": 1
                    },
                    {
                        "source": "干细胞及转化研究",
                        "target": "国家青藏高原科学数据中心",
                        "value": 1
                    },
                    {
                        "source": "中国生物技术发展中心",
                        "target": "生物安全关键技术研究",
                        "value": 1
                    },
                    {
                        "source": "生物安全关键技术研究",
                        "target": "国家人口健康科学数据中心",
                        "value": 1
                    },
                    {
                        "source": "合成生物学",
                        "target": "国家农业科学数据中心",
                        "value": 1
                    },
                    {
                        "source": "生物安全关键技术研发",
                        "target": "国家计量科学数据中心",
                        "value": 1
                    },
                    {
                        "source": "生物安全关键技术研发",
                        "target": "国家农业科学数据中心",
                        "value": 1
                    },
                    {
                        "source": "颠覆性技术（生物技术领域）",
                        "target": "国家人口健康科学数据中心",
                        "value": 1
                    },
                    {
                        "source": "生物安全关键技术研发",
                        "target": "国家青藏高原科学数据中心",
                        "value": 1
                    },
                    {
                        "source": "主动健康和老龄化科技应对",
                        "target": "国家基础学科公共科学数据中心",
                        "value": 1
                    },
                    {
                        "source": "生物医用材料研发与组织器官修复替代",
                        "target": "国家基因组科学数据中心",
                        "value": 2
                    },
                    {
                        "source": "中医药现代化研究",
                        "target": "国家微生物科学数据中心",
                        "value": 2
                    },
                    {
                        "source": "中医药现代化研究",
                        "target": "国家基因组科学数据中心",
                        "value": 2
                    },
                    {
                        "source": "生物安全关键技术研发",
                        "target": "国家人口健康科学数据中心",
                        "value": 3
                    },
                    {
                        "source": "生物医用材料研发与组织器官修复替代",
                        "target": "国家计量科学数据中心",
                        "value": 3
                    },
                    {
                        "source": "主动健康和老龄化科技应对",
                        "target": "国家微生物科学数据中心",
                        "value": 4
                    },
                    {
                        "source": "主动健康和老龄化科技应对",
                        "target": "国家基因组科学数据中心",
                        "value": 4
                    },
                    {
                        "source": "生物医用材料研发与组织器官修复替代",
                        "target": "国家材料腐蚀与防护科学数据中心",
                        "value": 4
                    },
                    {
                        "source": "生物医用材料研发与组织器官修复替代",
                        "target": "国家基础学科公共科学数据中心",
                        "value": 4
                    },
                    {
                        "source": "食品安全关键技术研发",
                        "target": "国家计量科学数据中心",
                        "value": 4
                    },
                    {
                        "source": "数字诊疗装备研发",
                        "target": "国家高能物理科学数据中心",
                        "value": 4
                    },
                    {
                        "source": "中国生物技术发展中心",
                        "target": "颠覆性技术（生物技术领域）",
                        "value": 5
                    },
                    {
                        "source": "颠覆性技术（生物技术领域）",
                        "target": "国家微生物科学数据中心",
                        "value": 4
                    },
                    {
                        "source": "重大慢性非传染性疾病防控研究",
                        "target": "国家微生物科学数据中心",
                        "value": 5
                    },
                    {
                        "source": "中国生物技术发展中心",
                        "target": "诊疗装备与生物医用材料",
                        "value": 6
                    },
                    {
                        "source": "诊疗装备与生物医用材料",
                        "target": "国家人口健康科学数据中心",
                        "value": 6
                    },
                    {
                        "source": "食品安全关键技术研发",
                        "target": "国家人口健康科学数据中心",
                        "value": 6
                    },
                    {
                        "source": "数字诊疗装备研发",
                        "target": "国家基础学科公共科学数据中心",
                        "value": 6
                    },
                    {
                        "source": "中国生物技术发展中心",
                        "target": "生物与信息融合（BT与IT融合）",
                        "value": 6
                    },
                    {
                        "source": "生物与信息融合（BT与IT融合）",
                        "target": "国家人口健康科学数据中心",
                        "value": 6
                    },
                    {
                        "source": "公共安全风险防控与应急技术装备",
                        "target": "国家基因组科学数据中心",
                        "value": 7
                    },
                    {
                        "source": "合成生物学",
                        "target": "国家基础学科公共科学数据中心",
                        "value": 7
                    },
                    {
                        "source": "重大慢性非传染性疾病防控研究",
                        "target": "国家基因组科学数据中心",
                        "value": 9
                    },
                    {
                        "source": "数字诊疗装备研发",
                        "target": "国家微生物科学数据中心",
                        "value": 10
                    },
                    {
                        "source": "合成生物学",
                        "target": "国家基因组科学数据中心",
                        "value": 11
                    },
                    {
                        "source": "合成生物学",
                        "target": "国家人口健康科学数据中心",
                        "value": 11
                    },
                    {
                        "source": "中国生物技术发展中心",
                        "target": "脑科学与类脑研究",
                        "value": 13
                    },
                    {
                        "source": "脑科学与类脑研究",
                        "target": "国家人口健康科学数据中心",
                        "value": 13
                    },
                    {
                        "source": "食品安全关键技术研发",
                        "target": "国家农业科学数据中心",
                        "value": 13
                    },
                    {
                        "source": "生物医用材料研发与组织器官修复替代",
                        "target": "国家微生物科学数据中心",
                        "value": 13
                    },
                    {
                        "source": "中国生物技术发展中心",
                        "target": "干细胞研究与器官修复",
                        "value": 15
                    },
                    {
                        "source": "干细胞研究与器官修复",
                        "target": "国家人口健康科学数据中心",
                        "value": 15
                    },
                    {
                        "source": "干细胞及转化研究",
                        "target": "国家微生物科学数据中心",
                        "value": 16
                    },
                    {
                        "source": "数字诊疗装备研发",
                        "target": "国家计量科学数据中心",
                        "value": 17
                    },
                    {
                        "source": "中国生物技术发展中心",
                        "target": "常见多发病防治研究",
                        "value": 19
                    },
                    {
                        "source": "常见多发病防治研究",
                        "target": "国家人口健康科学数据中心",
                        "value": 19
                    },
                    {
                        "source": "中国生物技术发展中心",
                        "target": "生物安全关键技术研发",
                        "value": 44
                    },
                    {
                        "source": "生物安全关键技术研发",
                        "target": "国家微生物科学数据中心",
                        "value": 35
                    },
                    {
                        "source": "公共安全风险防控与应急技术装备",
                        "target": "国家微生物科学数据中心",
                        "value": 36
                    },
                    {
                        "source": "中国生物技术发展中心",
                        "target": "绿色生物制造",
                        "value": 38
                    },
                    {
                        "source": "绿色生物制造",
                        "target": "国家微生物科学数据中心",
                        "value": 37
                    },
                    {
                        "source": "中国生物技术发展中心",
                        "target": "食品安全关键技术研发",
                        "value": 61
                    },
                    {
                        "source": "食品安全关键技术研发",
                        "target": "国家微生物科学数据中心",
                        "value": 38
                    },
                    {
                        "source": "中国生物技术发展中心",
                        "target": "生物医用材料研发与组织器官修复替代",
                        "value": 77
                    },
                    {
                        "source": "生物医用材料研发与组织器官修复替代",
                        "target": "国家人口健康科学数据中心",
                        "value": 51
                    },
                    {
                        "source": "干细胞及转化研究",
                        "target": "国家基因组科学数据中心",
                        "value": 56
                    },
                    {
                        "source": "中国生物技术发展中心",
                        "target": "公共安全风险防控与应急技术装备",
                        "value": 100
                    },
                    {
                        "source": "公共安全风险防控与应急技术装备",
                        "target": "国家人口健康科学数据中心",
                        "value": 57
                    },
                    {
                        "source": "中国生物技术发展中心",
                        "target": "干细胞及转化研究",
                        "value": 137
                    },
                    {
                        "source": "干细胞及转化研究",
                        "target": "国家人口健康科学数据中心",
                        "value": 63
                    },
                    {
                        "source": "中国生物技术发展中心",
                        "target": "主动健康和老龄化科技应对",
                        "value": 89
                    },
                    {
                        "source": "主动健康和老龄化科技应对",
                        "target": "国家人口健康科学数据中心",
                        "value": 78
                    },
                    {
                        "source": "中国生物技术发展中心",
                        "target": "合成生物学",
                        "value": 113
                    },
                    {
                        "source": "合成生物学",
                        "target": "国家微生物科学数据中心",
                        "value": 83
                    },
                    {
                        "source": "中国生物技术发展中心",
                        "target": "中医药现代化研究",
                        "value": 131
                    },
                    {
                        "source": "中医药现代化研究",
                        "target": "国家人口健康科学数据中心",
                        "value": 126
                    },
                    {
                        "source": "中国生物技术发展中心",
                        "target": "重大慢性非传染性疾病防控研究",
                        "value": 165
                    },
                    {
                        "source": "重大慢性非传染性疾病防控研究",
                        "target": "国家人口健康科学数据中心",
                        "value": 151
                    },
                    {
                        "source": "中国生物技术发展中心",
                        "target": "数字诊疗装备研发",
                        "value": 205
                    },
                    {
                        "source": "数字诊疗装备研发",
                        "target": "国家人口健康科学数据中心",
                        "value": 166
                    }
                ],
                "nodeGap": 30,
                "nodeWidth": 30,
                "right": "25%",
                top: "7%",
                bottom: "4%",
                left:"25",
                "type": "sankey"
            },
            "zyjg": "中国生物技术发展中心"
        }
    ]


export default data
